.react-tags {
    flex: 2;
    position: relative;
    width:100%;
    height: 100%;
    margin-left: 5px;
    border: 1px solid black;
    border-radius: 10px;
    background: #ffffff;
    display:flex;
    align-items: center;
    text-align: left;
    /* shared font styles */
    font-size: 10px;  
    /* clicking anywhere will focus the input */
    cursor: text;
    margin-right: 0px;
  }
  
  .react-tags__list {
    /* Do not use display: contents, it's too buggy */
    display: inline;
    align-items: center;
    padding: 0;      
  }
  
  .react-tags__list-item {
    display: inline;
    list-style: none;  
  }
  
  
  .react-tags__tag {
    margin: 0 0.25rem 0.25rem 0;
    border: 0;
    border-radius: 6px;
    background: #eaeef2;
    /* match the font styles */
    font-size: 10px;
    font-weight: normal;
    line-height: inherit;
    width: 60px; /* Set a fixed width for each tag */
    overflow: hidden; /* Hide overflowing text */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis;
  }
  
  .react-tags__tag.adopt {
    background-color: #d4ebd5;
  }

  .react-tags__tag.adopt.selected {
    background-color: #037809;
    color: white;
    font-weight: bold;
  }

  .react-tags__tag.shed {
    background-color: rgb(235, 198, 198);
  }

  .react-tags__tag.shed.selected {
    background-color: red;
    color: white;
    font-weight: bold;
  }

  .react-tags__tag.adopt::after {
    display: none;
  }

  .react-tags__tag.shed::after {
    display: none;
  }

  .react-tags__tag:hover {
    color: #ffffff;
    background-color: #4f46e5;
    position: relative;
  }
  
  .react-tags__tag::after {
    content: '';
    display: inline-block;
    width: 0.65rem;
    height: 0.65rem;
    clip-path: polygon(10% 0, 0 10%, 40% 50%, 0 90%, 10% 100%, 50% 60%, 90% 100%, 100% 90%, 60% 50%, 100% 10%, 90% 0, 50% 40%);
    margin-left: 0.5rem;
    font-size: 0.875rem;
    background-color: #7c7d86;
  }
  
  .react-tags__tag:hover::after {
    background-color: #ffffff;
  }
  
  .react-tags__combobox {
    /* prevents autoresize overflowing the container */
    flex: 1;
    display: flex
  }
  
  .react-tags__combobox-input {
    /* prevent autoresize overflowing the container */
    flex: 1;
    /* remove styles and layout from this element */
    margin: 10px;
    padding: 0;
    border: 0;
    outline: none;
    background: none;
  }
  
  .react-tags__listbox {
    position: absolute;
    z-index: 1;
    top: calc(100% + 5px);
    /* Negate the border width on the container */
    left: -2px;
    right: -2px;
    font-size: small;
    max-height: 10rem;
    overflow-y: auto;
    background: #ffffff;
    border: 1px solid #afb8c1;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
  }
  
  .react-tags__listbox-option {
    padding: 0.375rem 0.5rem;
  }
  
  .react-tags__listbox-option:hover {
    cursor: pointer;
    background: #eaeef2;
  }
  
  .react-tags__listbox-option:not([aria-disabled='true']).is-active {
    background: #4f46e5;
    color: #ffffff;
  }
  
  .react-tags__listbox-option[aria-disabled='true'] {
    color: #7c7d86;
    cursor: not-allowed;
    pointer-events: none;
  }
  
  .react-tags__listbox-option[aria-selected='true']::after {
    content: '✓';
    margin-left: 0.5rem;
  }
  
  .react-tags__listbox-option[aria-selected='true']:not(.is-active)::after {
    color: #4f46e5;
  }
  
  .react-tags__listbox-option-highlight {
    background-color: #ffdd00;
  }
  
  .icon-space {
    margin-right: 10px; /* Adjust the margin-right value to control the space between the icons */
  }
  
  
/* General Styles */
 
  .goal-page {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    z-index: 10;
    padding: 10px;
  }
  
  .goal-page .title {
    display: flex;
    justify-content: space-between;
    align-self: center;
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 20px;
    width: 100%;
  }

  .goal-page .switch-mode {
    margin-right: 5px;
    color: black;
  }

  .goal-page .instruction {
    margin-left: 0px;
    margin-bottom: 2px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
  }

  .goal-page .card-body {
    display: flex;
    flex-direction: column;
  }

  .goal-page .next-step {
    width: fit-content;
    align-self: baseline;
    margin: 5px;
    background-color: rgb(1, 96, 7);
    font-size: 12px;
  }

  .goal-page .svg-inline--fa {
    margin-left: 0px;
  }

  .goal-help {
    font-size: 12px;
    color: blue;
    align-self: flex-end;
  }

  .identity {
    background-color: white;
    border-radius: 15px;
    width: 95%;
    min-height: 40px;
    align-self: baseline;
    padding: 5px;
    margin: 10px;
    border: transparent;
    align-content: baseline;
    justify-content: baseline;
    font-size: 12px;
  }

  .goal-identity-header {
    display: flex;
    flex-direction: column;    
  }
  
  .goal-page .save-goals {
    padding: 10px;
    width: 90%;
    border-radius: 20px;
    align-self: center;
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    .goal-page {
      width: 95%;
    }

    .goal-page .instruction {
      font-size: 12px;
    }

    .goal-page .title {
      font-size: 14px;
    }
  }
  
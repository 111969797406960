.feedback-widget {
    position: fixed;
    bottom: 10px;
    right: 10px;
    /*z-index: 5000;*/
  }

  .feedback-widget:hover {
    z-index: 5000;
  }
  
  .feedback-widget:active {
    z-index: 5000;
  } 

  .feedback-widget.open .feedback-button {
    display: none;
  }
  
  .feedback-widget .thank-you-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .feedback-widget .thank-you-message {
    flex: 1;
  }
  
  .feedback-widget .thank-you-wrapper .close-button {
    position: fixed;
    top: auto;
    left: auto;
    right: 3px;
    font-size: 25px;
    border: none;
    background-color: transparent;
    color: black;
  }  

  .feedback-form {
    background-color: #0db3fa;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 320px;
  }
  
  .feedback-form textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 5px;
    border-radius: 8px;
    resize: none;
  }
  
  .video-container {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    margin-bottom: 10px;
  }
  
  .video-control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .feedback-button {
    color: #fff;
    padding: 0px 0px;
    cursor: pointer;
    border: none;    
    /*z-index: 5000;*/
  }
  
  .feedback-button .feedback-icon {
    width: 100px;
    height: 30px;    
    border: 1px solid black;
    border-radius: 20px;
  }

  .sign-out-button {
    /* Update the styles to match the feedback widget */
    background-color:transparent;
    color: #fff;
    padding: 0px 0px;
    border: transparent;
    border-radius: 20px;
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    left: 10px;
    /*z-index: 5000;*/    
  }
  
  .sign-out-button .signout-icon {
    width: 100px;
    height: 30px;    
    border: 1px solid black;
    border-radius: 20px;
  }

  .sign-out-button:hover {
    /* Update the styles as needed */
    z-index: 5000;
  }
  
  .sign-out-button:active {
    /* Update the styles as needed */
    z-index: 5000;
  }

  .video-title {
    margin-bottom: 10px; /* Adjust the value as needed */
  }
  
  .error-message {
    color: red;
    font-style: italic;
    margin-bottom: 10px;
  }
  
  
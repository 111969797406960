body {
  padding: 0;
  margin: 0;
  background-color: cyan;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./self-care-image.png");
  background-size: cover;
  
}

/* Style the container element with a transparent background and some padding */
.container {
  width: 80%;
  margin: auto;
  padding: 20px;  
}

/* Style the text field element with some border and font properties */
.text-field {
  border: 2px solid black;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px;
}

/* Style the icons element with absolute positioning and some size properties */
.icons {
  position: absolute;
  width: 50px;
  height: 50px;
}

/* Position the first icon on the top left corner */
.icon-1 {
  top: 10px;
  left: 10px;
  src: url("./amplify.png");
}

/* Position the second icon on the top right corner */
.icon-2 {
  top: 10px;
  right: 10px;
  src: url("./self-care-image.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

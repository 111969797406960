/* FirstRunExperience.css */

.daily-challenge-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999; /* Set a high z-index to bring the modal on top of other elements */
  }
  
  .daily-challenge-content {
    background-color: rgb(139, 219, 193);
    padding: 20px;
    width: fit-content;
    max-width: 80%;
    height: fit-content;
    position: relative; 
    border: 2px solid #007bff; /* Border color and width */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .challenge-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .daily-challenge-container .icon {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 25px;    
  }

  .daily-challenge-content .screen-container {
    margin: 10px;
    font-size: 14px;    
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .daily-challenge-content .dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 1);
    padding: 20px;
    z-index: 2;
    display: flex;
    justify-items: center;
    align-content: center;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #007bff;
  }

  .challenge-complete {
    z-index: 9999;
  }

  /* Styling for scroll indicators */
.scroll-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 9999;
  color: rgba(0, 0, 0, 0.3);
}

.scroll-indicator:hover {
  color: rgba(0, 0, 0, 0.8);
}


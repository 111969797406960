
.flyout {
    /* Existing styles */
    position: fixed;
    top: 0;
    right: 0;
    width: fit-content;
    max-width: 50%;
    height: 100%;
    background: rgb(245, 245, 245);
    border: 1px solid black;
  
    /* Add animation properties */
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    opacity: 0;
    transform: translateX(100%);
    z-index: 5000;
    display: flex;
    flex-direction: column;  
  }
  
  @keyframes flyoutAnimation {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .flyout.show {
    /* Add animation properties */
    animation-name: flyoutAnimation;
  }  
  
  .flyout-header {
    display: flex;
    justify-content: flex-end;  
    padding-right: 5px;
  }
  
  .flyout-close {
    cursor: pointer;
    z-index: 9999;
  }
  
  @media screen and (max-width: 768px) {
    .flyout {
      max-width: 100%;
    }
  }
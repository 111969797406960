.challenge-form {
    margin-top: 20px;
    width: 100%
  }
  
  .typeahead-container {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
  }
  
  .typeahead-list {
    background-color: rgb(255, 255, 255);
    list-style-type: none;
    padding: 0;
    width: max-content;
    max-height: max-content;
    z-index: 1;    
    position: relative;
  }
  
  .typeahead-list li {
    display: flex;
    padding: 8px;
    cursor: pointer;
  }
  
  .typeahead-list li:hover {
    background-color: #f0f0f0;
  }
  
  .typeahead-list li.selected {
    background-color: #f0f0f0;
  }

  .selected-emails {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .email-tag {
    background-color: white;
    border-radius: 4px;
    padding: 4px 8px;
    margin: 4px;
    display: flex;
    align-items: center;
  }
  
  .remove-tag {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 4px;
    color: #888;
  }

  .invalid-email {
    border-color: red; /* Add red border for invalid email */
    color: red;
    width: 100%
  }

  .valid-email {
    border-color: black; /* Add red border for invalid email */
    color: black;
    width: 100%
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between; /* This will push the buttons to the sides */
    margin-top: 10px;
    width: 100%; /* Make the container as wide as the text field */
  }
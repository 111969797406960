/* Tooltip Styles */
.timer-container {
    position: relative;
    display: inline-block;
    margin-right: 0px; 
    width: 100%;   
  }
  
  .timer-container .text-field.time-input {
    border: 1px solid black;
    position: relative;
    margin-left: 0px;
    width: 100%;    
  }

  .timer-container .timer-tooltip-content {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s, visibility 0.5s;    
  }

  .timer-container:hover .timer-tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  
  
  .timer-tooltip-content button {
    border: none;
    background-color: transparent;
    font-size: 12px;
    text-align: left;
    cursor: pointer;
  }
 
  .timer-container .dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom:0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

  /* Dialog Styles */
  .timer-container .dialog-content {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 1);
    padding: 20px;
    z-index: 9999;
    display: flex;
    justify-items: center;
    align-content: center;
    width: 150px;
    height: 150px;
  }
  
  .timer-container .close-button {
    position: relative;
    top: -40px;
    right: -110px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #007bff;
  }

  .timer-container .stopwatch {
    align-self: center;
    text-align: center;
    color: green;
    font-size: 35px;
    display: flex;
  }

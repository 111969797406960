/* Style the chat container */
.self-care-coach-container  {
    align-self: center;
    position: relative;
    width: 100%;     
    height: calc(100% + 25px);
    display: flex;  
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 10;
    margin-top: -25px;
  }

.self-care-coach-container .rcw-widget-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;        
  }
  
  .self-care-coach-container .rcw-conversation-container {
    width: 100%; /* Use 100% to take the full width of the parent container */
    height: 100%; /* Use 100% to take the full width of the parent container */
    margin: auto;
    margin-top: 0px;
    border: none;
    border-radius: 5px;
    /*overflow-x: auto;*/
    position: relative;
  }

  /* Style the widget header (title, icon, etc.) */
  .self-care-coach-container .rcw-conversation-container .rcw-header {
    padding: 10px;
    padding-top: 20px;
    color: black;
    font-size: 12px;
    text-align: left;
    background-color:rgba(3, 126, 126, 0.162);
  }

  .self-care-coach-container .rcw-conversation-container .rcw-close-button {
    display: none;
  }

  .self-care-coach-container .rcw-conversation-container .rcw-header .rcw-title {
    font-size: 18px;
    color: black;
    padding: 0px;    
  }

  .self-care-coach-container .rcw-conversation-container .rcw-messages-container {
    height: calc(100vh - 250px);
    width: 100%;
    overflow: auto;
    font-size: 14px;
  }

  .self-care-coach-container .rcw-new-message {
    width: 100%;
    font-size: 14px;
  }

  .self-care-coach-container .rcw-client .rcw-message-text {
    align-self: flex-end;
    width: 100%;
    max-width: 100%;
  }

  .self-care-coach-container .rcw-response .rcw-message-text {
    align-self: flex-start;
    width: 100%;
    max-width: 100%;
  }

  .self-care-coach-container .rcw-picker-btn {
    display: none;
  }
  
  .self-care-coach-container .rcw-sender {
    align-items: center; /* Change to white or any text color that suits the background */
  }

  /* Style the send message button */
.self-care-coach-container .rcw-send-icon {
    height: 30px;
  }

  .self-care-coach-container .rcw-launcher.rcw-hide-sm {
    display: none;
  }

  .self-care-coach-container .quick-buttons-container {
    width: 100%;    
    white-space: break-spaces;
    overflow: auto;
  }

  .self-care-coach-container .quick-buttons-container .quick-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .self-care-coach-container .quick-buttons-container .quick-buttons .quick-list-button {
    margin: 5px;
  }

  .self-care-coach-container .quick-button {
    border: 1px solid cyan;
    font-size: 12px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    .self-care-coach-container .rcw-conversation-container .rcw-messages-container {
      width: 100%;
    }
  }
  
  

.leaderboard-container {
    width: 90%;
    margin: auto;
    margin-top: 50px;
    border: 1px solid black;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;
  }
  
  .medal-podium {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 10px;
    margin-top: -10px;
  }
  
  .medal-container.animate-silver {
    opacity: 0;
    animation: silverAnimation 1s ease-in-out 1000ms forwards;
  }
  
  .medal-container.animate-gold {
    opacity: 0;
    animation: goldAnimation 2s ease-in-out 1000ms forwards;
  }
  
  .medal-container.animate-bronze {
    opacity: 0;
    animation: bronzeAnimation 500ms ease-in-out 1000ms forwards;
  }
  
  @keyframes silverAnimation {
    0% {
      opacity: 0;
      transform: translateY(500px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes goldAnimation {
    0% {
      opacity: 0;
      transform: translateY(500px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bronzeAnimation {
    0% {
      opacity: 0;
      transform: translateY(500px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  .medal-icon-gold-medal {
    font-size: 50px;
    color: gold;
    margin: 0 20px;
  }
  
  .medal-icon-silver-medal {
    font-size: 50px;
    color:darkgray;
    margin: 0 20px;
  }

  .medal-icon-bronze-medal {
    font-size: 50px;
    color: #CD7F32;
    margin: 0 20px;
  }

  .bar-chart-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 0px;
    padding: 10px;
    transition: height 1s ease-in-out;
  }

  .bar-chart-container.animate {
    height: 200px; /* Adjust the height value to the desired animation endpoint */
  }

  .bar-chart {
    height: 100%;
    width: 20px;
    margin: 0 10px;
    background-color: #2196F3;
  }
  
  .bar-chart-gold {
    height: 100%;
    width: 20px;
    margin: 0 10px;
    background-color: gold;
  }
  
  .bar-chart-silver {
    height: 100%;
    width: 20px;
    margin: 0 10px;
    background-color: darkgray;
  }
  
  .bar-chart-bronze {
    height: 100%;
    width: 20px;
    margin: 0 10px;
    background-color: #CD7F32;
  }
  
  .other-participants-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    opacity:0;
    animation: fadeIn 3s ease-in-out 1000ms forwards;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(500px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
 /* .other-participant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 5px;
    padding: 10px;
    border-bottom: 1px solid black;
    animation: slideIn 1s ease-in-out;
  }*/
  
  .other-participant {    
    animation: slideIn 1s ease-in-out;
  }

  @keyframes slideIn {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .other-participant span {
    margin-right: 10px;
  }
  
  .first-place {
    color: gold;
  }
  
  .second-place {
    color: darkgray;
  }
  
  .third-place {
    color: #CD7F32;
  }
  
  .title-box {
    font-size: 16px;
    color: #306DC8;
    font-weight: 600;
  }

  .challenge-creation-container {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;
  }

  .challenge-results-container {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    border: 1px solid black;
    background-color: lightcyan;
    border-radius: 5px;
    overflow: hidden;
    z-index: 10;
  }
  
/* FirstRunExperience.css */

.fre-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999; /* Set a high z-index to bring the modal on top of other elements */
  }
  
  .fre-content {
    background-color: rgb(139, 219, 193);
    padding: 0px;
    width: 800px;
    max-width: 800px;
    height: 80vh;
    position: relative; 
    overflow-y: auto;
    border: 2px solid #007bff; /* Border color and width */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  }
  
  .screen-container {
    display: table-row;
    justify-content: center;
    position: absolute; /* Set position to absolute */
    bottom: 30px;
    left: 0px;
    overflow-y: auto;
    padding-left: 10px;
    width: 100%;
    height: 90%;
    font-size: 14px;
  }

  video {
    width: 60%; /* Set video width to match the container's width */
    height: auto; /* Maintain the aspect ratio of the video */
  }

  /* FeatureRow.js */
 .feature-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .video-thumbnail {
    flex: 1;
    margin-right: 20px;
  }
  
  .featuretext-container {
    flex: 2;
  }
  
  .video-thumbnail video {
    width: 100%;
  }
  
  /* Add any additional styling as needed for the video and text containers */
  
  .dot-container {
    display: flex;
    justify-content: center;
    position: absolute; /* Set position to absolute */
    bottom: 10px;
    left: 0;
    width: 100%;
  }
  
  /* Dot */
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #b794c8;
    margin: 0 5px;
    cursor: pointer;
  }
  
  /* Active Dot */
  .dot-active {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #9f10e7; /* Change to the desired color for the active dot */
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #007bff;
  }

  /* Styling for scroll indicators */
.scroll-indicator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  z-index: 9999;
  color: rgba(0, 0, 0, 0.3);
}

.scroll-indicator:hover {
  color: rgba(0, 0, 0, 0.8);
}


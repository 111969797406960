.user-badges {
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .badge-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
  
  .badge-heading {
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-bottom: 2px;
  }
  
  .badge-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .badge-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .icon {
    font-size: 40px;
  }
  
  .label {
    font-size: 20px;
    margin-top: 5px;
    text-align: center;
  }

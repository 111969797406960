.emojis-container {
  position: relative;
  display: inline-block;
}

.emoji-toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 15px;
  margin: 0px;
  padding: 0px;  
}

.emoji-picker {
  position: absolute;
  top: 10px; /* Adjust the positioning as needed */
  left: 20px;
  display: flex;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  padding: 4px;
  z-index: 1;  
  transform: translateX(-50%);
}

.emoji {
  cursor: pointer;
  font-size: 20px;
  margin: 4px;
}

.legend-container {
  align-self: center;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: right;
  margin-top: 20px;
  z-index: 10;
}

.summary-container {
  width: 100%; /* Use 100% to take the full width of the parent container */
  max-width: 100%; /* Add max-width to limit the container's width */
  height: 80%; /* Use 100% to take the full width of the parent container */
  max-height: 80%; /* Add max-width to limit the container's width */
  margin: auto;
  margin-top: 20px;
  border: 1px solid black;
  border-radius: 5px;
  overflow-x: auto;
  position: relative;
}

.summary-container .subheader {
  font-size: 15px;
}

.recharts-legend-wrapper {
  position: unset !important;
  display: flex;
  justify-content: center;
}

.legend-items {
  display: flex;
  justify-content: center;
}

.legend-item {
  margin: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.legend-item span {
  font-size: 18px;
}

.summary-container .summary-chart {
  width: 100%;
  height: 50%;
}

/* Set the chart height to a percentage value for smaller screens */
.summary-container .wordcloud-container {
  margin-top: 20px;
  width: 95%;
  height: 30%;
  display: flex;
  justify-content: left; 
  align-items: center; /* Vertically center align the WordClouds */
}

/* Style for each WordCloud component (adjust the width as needed) */
.wordcloud {
  flex: 0.5; 
  border: 1px solid black;
  border-radius: 5px;
  background-color: transparent;
  margin: 5px;
}

/* CSS for the label */
.label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 20px;
}

.label {
  transform: rotate(-90deg); /* Rotate the label */
  white-space: nowrap; /* Prevent label text from wrapping */
  text-align: center;
  font-weight: bold;
  font-size: 16px; 
  color: black; 
  margin-top: 0 auto; 
}

.summary-container .category {
  background-color: transparent;
  border-radius: 10px;
  font-size: 12px;
}

.summary-container .category option {
  background-color: transparent; /* Set the background color of options to transparent */
  color: black; /* Set the text color */  
}

/* Style the table */
.summary-habit-table {
  width: 100%;
  border-radius: 40px;
  border-collapse: collapse;  
  table-layout: fixed;
}


/* Style table headers */
.summary-habit-table th {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 2px;
  font-size: 10px;
  text-align: left;
}

/* Style table rows */
.summary-habit-table tr {
  border: 1px solid #ddd;
  font-size: 10px;
}

.summary-habit-table th:first-child,
.summary-habit-table td:first-child {
  width: 20%; 
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis;
  overflow: hidden;
}

.summary-habit-table th:hover, .summary-habit-table td:hover {
  white-space: normal;
  overflow: visible;
  text-overflow: inherit;
  max-width: none;
}

/* Style even rows */
.summary-habit-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style odd rows */
.summary-habit-table tr:nth-child(odd) {
  background-color: #fff;
}

/* Style table cells */
.summary-habit-table td {
  border: 1px solid #ddd;
  padding: 0px;
  text-align: left;  
}

.summary-habit-table .green {
  color: green;
  font-size: 12px;
}

.summary-habit-table .red {
  color: red;
  font-size: 12px;  
}

.summary-habit-table .no-show {
  display: none;
}

.summary-habit-table .goal-cell {
  background-color: rgb(232, 230, 230);
  color: black;
  font-weight: bold;
  font-style: italic;
}

/* Style green cells */
.summary-habit-table .green-cell {
  background-color: green;
  color: white;
}

.summary-habit-table .light-green-cell {
  background-color: white;
  color: white;
}

/* Style red cells */
.summary-habit-table .red-cell {
  background-color: red;
  color: white;
}

.summary-habit-table .light-red-cell {
  background-color: white;
  color: white;
}

.summary-habit-table .adopt {
  color: green;
  font-weight: bold;
  background-color: white;
}

.summary-habit-table .shed {
  color: red;
  font-weight: bold;
  background-color: white;
}

.summary-habit-table .indent {
  margin-left: 5px;
}

/* Add a media query to target screens with a maximum width of 768px (adjust as needed) */
@media screen and (max-width: 768px) {
  .legend-container {
    max-width: 95%; /* Adjust the container's max-width for smaller screens */
    max-height: 95%;
  }

  /* Reduce the font size for the legend items on smaller screens */
  .legend-item span {
    font-size: 12px;
  }  

  .summary-container .subheader {
    font-size: 12px;
  }

  .summary-container .wordcloud-container {
    /* Adjust the width and alignment of the WordCloud container for smaller screens */
    width: 100%; /* Use a percentage width to allow space for the label */    
  }

  .label-container {
    /* Dont show the label on very small screens */
    width: 0px;       
  }

  .label {
    /* Adjust the font size and margin for the label on smaller screens */
    font-size: 0px;
  } 

  .summary-habit-table th {
    padding: 0px;
    font-size: 8px;    
  }
  
  /* Style table rows */
  .summary-habit-table tr {
    font-size: 8px;    
  }
}


  
.enter-time-form .activity {
  text-wrap: nowrap;
}

.time-entry-input-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertically align items */
    justify-content: left;
  }
  
  .time-entry-input-container .formLabel {
    margin: 0px;
    font-size: 16px;  
    width: 80%;
    text-align: left;
  }

  .time-entry-input-container .text-field.time-input {
    margin: 2px;
    border: 1px solid black;
    border-radius: 5px;  
    font-size: 14px;  
    width: 100%;
    text-align: left;
    padding: 7px;
  }

  /* Style the time input */
  .time-entry-input-container .input {
    margin: 2px;
    border: 1px solid black;
    border-radius: 5px;  
    font-size: 14px;  
    width: 100%;
    text-align: left;
    padding: 5px;
  }

  
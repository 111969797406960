.goal-category {
    background-color: white;
    padding: 10px;
    margin: 10px;    
    margin-left: 20px;
    border-radius: 15px;
    width: 90%;
    border: 1px solid black;
    align-self: baseline;
  }
  
  .goal-category-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

    .goal-category-mental {
        border-left: 5px solid blue;        
    }
        
    .goal-category-physical {
    border-left: 5px solid #e44d4d;
    }

    .goal-category-spiritual {
    border-left: 5px solid #9d50bb;
    }

    .goal-category-social {
    border-left: 5px solid #50c878;
    }
    
  .goal-category table {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 12px;
    border-collapse: collapse;    
  }
  
  .goal-category th, .goal-category td {
    padding: 0px;
    border: 1px solid black;
  }
  
  .goal-category th:nth-child(1), 
  .goal-category th:nth-child(2), 
  .goal-category th:nth-child(3), 
  .goal-category td:nth-child(1), 
  .goal-category td:nth-child(2), 
  .goal-category td:nth-child(3) {
    width: calc((100% / 3) - 2px);/* Equal width for the first three columns */
  }

  .goal-category th:nth-child(4), 
  .goal-category td:nth-child(4) {
    width: 6px; /* Fixed width of 5px for the last column */
  }

  .goal-category input[type="text"] {
    width: 95%;
    padding: 2px;
    font-size: 14px;
    border: transparent;
    border-radius: 4px;
    font-family: -webkit-body;
  }

  .goal-category .delete-icon {
    margin-right: 0px;
  }
  
  .goal-category .react-tags {
    width:100%;
    height: 100%;
    margin: 0px;
    border: none;
    font-size: 12px;      
  }

  .goal-category .react-tags__tag-name {
    font-size: 12px;
  }

  .goal-category .react-tags__combobox-input {
    margin: 0px;
  }

  .goal-category button {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    margin: 5px;
    margin-left: 0px;
    padding: 1px 6px;
  }
  
  .goal-category button:hover {
    background-color: #45a049;
  }  
  
  .habitsToShed .react-tags__tag {
    background-color:rgb(234, 70, 70);
  }

  .habitsToShed .react-tags__tag:hover {
    background-color:red;
  }
.recommendation-tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    height: 90%;
    width: 100%;
    padding: 0px;
    /*width: 80%;*/
    border: 1px solid #ccc;
    outline: 1px solid #000; /* Add an outline */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a box shadow */
    z-index: 1;        
  }
  
  /* Add this CSS to style the icon container */
  .recommendation-tile .icon {
    flex: 1;
    width: 20%; 
    /*height: 100%;*/
    margin-right: 5px; 
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;        
  }
  
  /* Style the icon image within the container */
  .icon img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Adjust the object-fit property as needed */
    position: relative;
    border-radius: 50%; /* Make it circular */
    overflow: hidden;
    margin: 0 auto;
  }
  
  .recommendation-participants-info {
    position: relative;
    top: 20px;
    left: -20px;
    background-color: green; /* Badge background color */
    color: #fff; /* Badge text color */
    border-radius: 20%;
    padding-right: 5px;
    font-size: 12px;
    width: fit-content;
    cursor: default;
    z-index: 5000;
  }
  
  /* Style the share button */
.share-button {    
    font-size: 10px; /* Adjust font size */
    line-height: 0; /* Center the content vertically */
    position: relative;
    bottom: 15px;
    left: 30px;
    background-color: #306DC8; /* Button background color */
    color: #fff; /* Button text color */
    border: none;
    border-radius: 20%;
    padding: 2px 10px;
    width: fit-content;
    cursor: pointer;
    z-index: 5000;
  }
  

  /* Adjust the margin for spacing between the icon and the recommendation title */
  .recommendation-tile .title {
    flex: 3;
    align-content: center;
    justify-content: center;
    margin-left: 20px; /* Adjust the margin as needed */
    margin-right: 20px;
    font-size: 15px;
    margin-bottom: 5px;
  }
  
  
  .contributor {
    flex: 2;
    align-content: center;
    justify-content: center;
    font-size: 12px;
    margin-bottom: 5px;
  }
    

/* Add this to your CSS file */
.join-button {
    background-color: #4caf50; /* Green background color */
    border: none;
    color: white; /* White text color */
    padding: 5px 10px; /* Padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth color transition */
    }
    
    .join-button:hover {
    background-color: #45a049; /* Darker green on hover */
    }
    
    .leave-button {
    background-color: rgb(164, 8, 8); /* Green background color */
    border: none;
    color: white; /* White text color */
    padding: 5px 10px; /* Padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth color transition */
    }
    
    .leave-button:hover {
    background-color: red; /* Darker green on hover */
    }

    .recommendation-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;        
        margin-bottom: 10px;
        padding: 5px;
        width: 100%;
        border: 1px solid #ccc;
        outline: 1px solid #000; /* Add an outline */
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a box shadow */
        z-index: 1;
      }

      .personalized-details {
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        padding: 5px;
        background-color: cyan;
        width: 100%;
        border: 1px solid #ccc;
        outline: 1px solid #000; /* Add an outline */
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a box shadow */
        z-index: 1;
      }
    
      /* Add this CSS to style the comments section */
.comments-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
  border: 1px solid #ccc;
  outline: 1px solid #000; /* Add an outline */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add a box shadow */
  z-index: 1;
}

.comments-block {  
  width: 100%;
  max-width: 500px;
}

/* Style individual comments */
.comment {
  padding: 5px;
  margin-bottom: 30px;
  min-width: 30%;
  max-width: 75%;
  width:fit-content;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
  text-align: left; 
  position: relative;
}

.comment .username {  
  display: block; /* This makes the username appear on a new line, aligned to the right */
  text-align: right;
}

/* Style the user's own comments with an indentation */
.own-comment {
  margin-left:auto;
  background-color: cyan;  
  text-align: left;
  position: relative;  
}

.own-comment .username {  
  display: block; /* This makes the username appear on a new line, aligned to the right */
  text-align: right;
}

.comment-actions {  
  position: absolute;
  top: -5px;
  right: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  visibility: hidcen;
  transition: opacity 0.5s, visibility 0.5s;
}

.comment:hover .comment-actions {
  opacity: 1;
  visibility: visible;
}

.edit-icon,
.delete-icon {
  cursor: pointer;
  margin-right: 5px;
}

.comment-reactions {  
  position: absolute;
  font-size: 20px;
  bottom: -25px; 
  right: -10px; 
  z-index: 5;
}

.comment-reaction {
  background-color: white;
  border-radius: 20px;
  border: 1px solid gray;
  padding-left:10px;
  padding-right:10px;  
  margin-right: 3px;
}

.comment-reaction .emoji {
  font-size: 20px; /* Adjust the font size for the emoji */
  margin: 0px;
}

.comment-reaction .count {
  font-size: 14px; /* Adjust the font size for the count */  
}

.new-comment {  
  width: 100%;
  max-width: 500px;
  margin: 5px;
  padding: 5px;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  font-size: 14px;
}

.new-comment input {
  width: 80%;
  text-align: center;  
  margin-top: 20px;
  margin-left: auto;
  margin-right: 5px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 5px;
}



 
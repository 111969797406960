.recommendations-page {
    display: grid;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    padding-top: 0px;
    max-height: calc(100vh - 55px); /* Adjust the height as needed */
    max-width: calc(100vh);
    width: 100%;
    overflow-y: auto;  
    overflow-x: auto;  
    font-size: 12px;
  }    

  .emptydiv {
    flex: 0 0 0; /* flex-grow: 0; flex-shrink: 0; flex-basis: 0; */
  }

/* Style the filter section */
.filter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adjust the gap between filter options */
  margin-bottom: 20px; /* Add spacing between filters and recommendation tiles */
}

/* Style the filter icons */
.filter-icon {
  width: 50px; /* Set the width of the filter icons */
  height: 50px; 
  margin-right: 5px; /* Add spacing between icon and label */
}

/* Style the radio labels for better UX */
.filter-section label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.preview {
  margin-bottom: 10px;
}

.preview iframe {
  width: 100%;
  height: 200px; /* Adjust the height as needed */
}

.preview img {
  width: 100%;
  max-height: 100px;
}

.back-to-recommendations {
  position: fixed;
  bottom: 0;
  left: 10;
  width: 100%;
  height: 30px;
  padding: 2px;
  display: flex;
  justify-content: left;
  font-size: 14px;
  z-index: 9999;
}

.add-recommendation {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #fff;
    padding: 2px;
    display: flex;
    justify-content: center;
    z-index: 9999;
}


.radio-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
}

.radio-label {
  margin-right: 10px;
}

.icon-container {
  position: relative;
}

.tooltip {
  visibility: hidden;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.newreco-icon {
  width: 50px; /* Set the width of the filter icons */
  height: 50px; 
  margin-right: 2px; /* Add spacing between icon and label */  
}

.add-recommendation .title-field {
  width: 175%;
  padding: 5px;
  margin-left: 5px;
  margin-bottom: 2px;
  border: 1px solid black;
  border-radius: 4px;
}

.add-recommendation .type-field {
  width: 40%;
  padding: 5px;
  margin-left: 5px;
  margin-bottom: 2px;
  border: 1px solid black;
  border-radius: 4px;
}

.save-button, .cancel-button {
  /*background-color: white;*/
  color: black;
  border-color: black;
  /*padding: 2px;*/
  margin-bottom: 2px;
  cursor: pointer;
  border-radius: 4px;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .recommendations-page {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .filter-section {
    flex-direction: column; /* Stack filter options vertically */
    gap: 5px; /* Reduce gap between filter options */
  }

  .filter-icon {
    width: 30px; /* Adjust the filter icon size */
    height: 30px;
  }

  .filter-section label {
    margin-bottom: 5px; /* Add spacing between filter labels */
  }

  .add-recommendation {
    height: auto; /* Allow the add-recommendation section to expand */
    padding: 5px; /* Reduce padding for smaller screens */
  }

  .radio-buttons {
    flex-direction: row; /* Reset the direction for radio buttons */
    justify-content: space-between; /* Distribute radio buttons horizontally */
    margin-bottom: 5px; /* Reduce margin for smaller screens */
  }

  .radio-label {
    margin-right: 10px; /* Restore margin for radio labels */
  }

  .icon-container {
    position: initial; /* Reset position for icon container */
  }

  .tooltip {
    visibility: visible; /* Always show tooltips on smaller screens */
    opacity: 1; /* Ensure full visibility for tooltips */
    position: initial; /* Reset position for tooltips */
    bottom: initial;
    left: initial;
    transform: none; /* Reset transform for tooltips */
  }

  .newreco-icon {
    width: 20px; /* Adjust the filter icon size */
    height: 20px;
    margin-right: 2px; /* Restore margin for filter icons */
  }

  .input-field {
    margin-bottom: 5px; /* Reduce margin for input fields */
  }

  .save-button,
  .cancel-button {
    margin-bottom: 5px; /* Reduce margin for buttons */
  }
}


